import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { processArticleContent, processSimpleHTMLContent } from '../../utils/processHTMLContent';
import { getAuthorDetails, getFaqs, getRelatedPosts } from '../../utils/helpers';
import Seo from '../../components/Seo';
import { getSpecialOffer } from '../../utils/helpers/card';
import { getComments } from '../../utils/helpers/comments';
import Article from '../../components/Article/Article';
import { IFact } from '../../components/FullFeatured/FullFeatured.def';
import { IHotelLoyaltyProgramQuickFacts } from '../../models/IHotelLoyaltyProgramQuickFacts';
import { TTemplate } from '../../models/TTemplate';
import HTPageWrapper from '../../wrappers/HTPageWrapper';
import { HT_DEFAULT_PAGE_NAME } from '../../constants';

interface IDataTemplate {
  post: Queries.WpPost;
}

interface IPageContextTemplate {
  sidebar: Queries.WpUpOption;
  databaseId: number;
  tags: any;
  link: string;
  categories: any;
  specialOffer?: number;
}

const HotelLoyaltyProgramTemplate = ({
  data,
  pageContext,
  location,
}: PageProps<IDataTemplate, IPageContextTemplate>) => {
  const {
    post: {
      content = '',
      author,
      title,
      categories,
      modifiedGmt,
      dateGmt,
      featuredImage,
      commentCount,
      comments: postComments,
      commentStatus,
      template,
      cardMentionsAll,
      nonAffiliateAll,
      fieldsForPosts,
      databaseId,
    },
  } = data;
  const placing = '/TE/';
  const templateName: TTemplate = 'Full Width Hero Image + Sidebar';
  const { articleContent } = processArticleContent(content || '', placing, templateName);
  const sidebarGeneralSettings = pageContext?.sidebar;
  const authorDetails = getAuthorDetails(author?.node);
  const numberOfComments = commentCount ? commentCount : 0;
  const comments = getComments(postComments, databaseId);
  const faqs = getFaqs(fieldsForPosts?.upgpPostFaqs);
  const relatedPosts = getRelatedPosts(fieldsForPosts?.upgpPostRelatedPosts);
  const overrideSidebar = fieldsForPosts?.upgpOverrideDefaultSidebar ?? false;
  const sidebarPost = fieldsForPosts?.upgpCategorySidebarBlocks;
  const specialOffer = getSpecialOffer(pageContext?.specialOffer);
  const postCategories = categories ? categories.nodes : [];
  const facts: IFact[] = [];

  if ((template as any).hotelLoyaltyProgramDetails) {
    const hotelLoyaltyQuickFacts: IHotelLoyaltyProgramQuickFacts = (template as any)
      .hotelLoyaltyProgramDetails;

    if (hotelLoyaltyQuickFacts.upgpHotelLoyaltyProgramWebsite) {
      facts.push({
        label: 'Hotel Website',
        children: processSimpleHTMLContent(
          hotelLoyaltyQuickFacts.upgpHotelLoyaltyProgramWebsite,
          placing,
        ),
      });
    }

    if (hotelLoyaltyQuickFacts.upgpHotelLoyaltyProgramName) {
      facts.push({
        label: 'Loyalty Program',
        children: processSimpleHTMLContent(
          hotelLoyaltyQuickFacts.upgpHotelLoyaltyProgramName,
          placing,
        ),
      });
    }

    if (hotelLoyaltyQuickFacts.upgpHotelLoyaltyProgramBrands) {
      facts.push({
        label: 'Hotel Brands',
        children: processSimpleHTMLContent(
          hotelLoyaltyQuickFacts.upgpHotelLoyaltyProgramBrands,
          placing,
        ),
      });
    }

    if (hotelLoyaltyQuickFacts.upgpHotelLoyaltyProgramPointsAreWorth) {
      facts.push({
        label: 'Points Are Worth',
        children: processSimpleHTMLContent(
          hotelLoyaltyQuickFacts.upgpHotelLoyaltyProgramPointsAreWorth,
          placing,
        ),
      });
    }

    if (hotelLoyaltyQuickFacts.upgpHotelLoyaltyProgramAwardChartLink) {
      facts.push({
        label: 'Award Chart Link',
        children: processSimpleHTMLContent(
          hotelLoyaltyQuickFacts.upgpHotelLoyaltyProgramAwardChartLink,
          placing,
        ),
      });
    }

    if (hotelLoyaltyQuickFacts.upgpHotelLoyaltyProgramEarnPointsmiles) {
      facts.push({
        label: 'Earn Points/Miles',
        children: processSimpleHTMLContent(
          hotelLoyaltyQuickFacts.upgpHotelLoyaltyProgramEarnPointsmiles,
          placing,
        ),
      });
    }

    if (hotelLoyaltyQuickFacts.upgpHotelLoyaltyProgramRedeemPointsmiles) {
      facts.push({
        label: 'Redeem Points/Miles',
        children: processSimpleHTMLContent(
          hotelLoyaltyQuickFacts.upgpHotelLoyaltyProgramRedeemPointsmiles,
          placing,
        ),
      });
    }

    if (hotelLoyaltyQuickFacts.upgpHotelLoyaltyProgramReview) {
      facts.push({
        label: 'Hotel Review',
        children: processSimpleHTMLContent(
          hotelLoyaltyQuickFacts.upgpHotelLoyaltyProgramReview,
          placing,
        ),
      });
    }
  }
  const toggleElements: string[] =
    typeof fieldsForPosts?.upgpPostToggleElements?.length !== 'undefined'
      ? fieldsForPosts.upgpPostToggleElements.map((el) => el ?? '')
      : [];
  return (
    <HTPageWrapper
      category={'Travel, Hotels, Loyalty'}
      title={title || HT_DEFAULT_PAGE_NAME}
      location={location}
    >
      <Article
        loyaltyHeader={{
          alt: title || '',
          image: featuredImage?.node?.sourceUrl || null,
          asideMeta: {
            mainTitle: title || '',
            headline: 'Hotel Loyalty Rewards',
            author: {
              name: authorDetails.name,
            },
            lastUpdated: modifiedGmt || '',
            datePublished: dateGmt || '',
            authorLink: authorDetails.url,
            breadCrumbs: [
              {
                link: '/travel/hotels/',
                text: 'Hotel',
              },
              {
                link: '/travel/hotels/',
                text: 'Rewards',
              },
            ],
          },
        }}
        articleContent={articleContent}
        authorInfo={{
          authorName: authorDetails.name,
          content: authorDetails.bio || '',
          link: authorDetails.url,
          socials: authorDetails.socials,
          thumbnail: {
            src: authorDetails.imageUrl,
            alt: authorDetails.imageAlt,
          },
        }}
        comments={{
          comments,
          postDatabaseId: databaseId,
          total: numberOfComments,
          commentsClosed: commentStatus === 'closed',
        }}
        cardMentions={cardMentionsAll}
        nonAffiliate={nonAffiliateAll}
        faqs={{
          faqs,
        }}
        postId={databaseId}
        relatedPosts={{
          relatedPosts,
        }}
        sidebarData={{
          articleId: databaseId,
          sidebar: sidebarGeneralSettings,
          categories: postCategories,
          overrideSidebarPost: overrideSidebar,
          sidebarPost: sidebarPost,
        }}
        template={templateName}
        weRecommend={specialOffer}
        pulse={{
          isPopup: false,
        }}
        quickFacts={{
          facts,
        }}
        toggleElements={toggleElements}
        context={{
          databaseId: data?.post?.databaseId || 0,
          tags: data?.post?.tags || null,
          link: data?.post?.uri || '',
          categories: data?.post?.categories || null,
          templateName: data?.post?.template?.templateName || '',
        }}
      />
    </HTPageWrapper>
  );
};

export default HotelLoyaltyProgramTemplate;

export const Head = ({ data }: PageProps<IDataTemplate, IPageContextTemplate>) => {
  const { title, seo, fieldsForPosts, categories } = data.post;
  return (
    <>
      <Seo
        title={title || ''}
        wpSeo={seo}
        faqs={fieldsForPosts?.upgpPostFaqs}
        categories={categories}
      />
    </>
  );
};

export const query = graphql`
  query HotelLoyaltyProgram($id: String) {
    post: wpPost(id: { eq: $id }) {
      ...PostData
    }
  }
`;
